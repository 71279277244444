import { type CameraControls } from '@react-three/drei'
import { type FileItem } from '../components/file-uploader'
import { GetDesignFromPersistedId } from '../components/get-design-from-persisted-id'
import { RAL_COLORS, type Ral, type RalCode } from '../lib/ral-colors'
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  type FC,
  type MutableRefObject,
} from 'react'
import { useSearchParams } from 'react-router-dom'

import { type Design } from './useApi'

export type Handle = 'greb' | 'stropper' | 'baerestaenger'
export type Model = 'orbit' | 'flex' | 'ligb'
export type PrintSide =
  | 'Top'
  | 'Venstre side'
  | 'Højre side'
  | 'Front'
  | 'Bagside'
export const PRINT_SIDES: PrintSide[] = [
  'Top',
  'Venstre side',
  'Højre side',
  'Front',
  'Bagside',
]
export type Print = {
  fileItem?: FileItem
  zoom: number
  offsetX: number
  offsetY: number
  rotation: number
}
export type Prints = Record<PrintSide, Print | undefined>
export type CameraPosition = { x: number; y: number; z: number }

export const emptyPrint: Print = {
  fileItem: undefined,
  zoom: 0.5,
  offsetX: 0,
  offsetY: 0,
  rotation: 0,
}

export const emptyPrints: Prints = {
  Top: emptyPrint,
  'Venstre side': emptyPrint,
  'Højre side': emptyPrint,
  Front: emptyPrint,
  Bagside: emptyPrint,
}

type State = {
  activeTab: string
  setActiveTab: (tab: string) => void

  ral: (typeof RAL_COLORS)[number]
  ralLid: (typeof RAL_COLORS)[number]
  ralHandles: (typeof RAL_COLORS)[number]
  ralPlinth: (typeof RAL_COLORS)[number]
  ralTopPitch: (typeof RAL_COLORS)[number]

  currentPrintSide: PrintSide
  setCurrentPrintSide: React.Dispatch<React.SetStateAction<PrintSide>>

  updateCameraPosition: (position: [number, number, number]) => Promise<void>

  printPanelIsOpen: boolean
  setPrintPanelIsOpen: React.Dispatch<React.SetStateAction<boolean>>

  activeDesignId: string | null
  setActiveDesignId: (designId: string | null) => void
  latestDesignFromServer: Design | null
  setLatestDesignFromServer: (design: Design | null) => void
  clearPersistedData: () => void

  // Design
  ralCode: RalCode
  setRalCode: (ralCode: RalCode) => void

  ralCodeLidActive: boolean
  setRalCodeLidActive: (active: boolean) => void
  ralCodeLid: string
  setRalCodeLid: (ralCodeLid: string) => void

  ralCodeHandlesActive: boolean
  setRalCodeHandlesActive: (active: boolean) => void
  ralCodeHandles: RalCode
  setRalCodeHandles: (ralCodeHandles: RalCode) => void

  ralCodePlinthActive: boolean
  setRalCodePlinthActive: (active: boolean) => void
  ralCodePlinth: RalCode
  setRalCodePlinth: (ralCodePlinth: RalCode) => void

  ralCodeTopPitchActive: boolean
  setRalCodeTopPitchActive: (active: boolean) => void
  ralCodeTopPitch: RalCode
  setRalCodeTopPitch: (ralCodeTopPitch: RalCode) => void

  handles: Handle
  setHandles: (handle: Handle) => void
  handleCount: number
  setHandleCount: (count: number) => void
  model: Model
  setModel: (model: Model) => void
  prints: Prints
  setPrints: (prints: Prints) => void
  updatePrints: (printSide: PrintSide, print: Partial<Print>) => void

  cameraControlRef: MutableRefObject<CameraControls | null>
}

const whiteRal = RAL_COLORS.find((color) => color.ral === 'Hvid')!

const defaultValue: State = {
  // Temporary state
  activeTab: 'type',
  setActiveTab: () => null,
  ral: whiteRal,
  ralLid: whiteRal,
  ralHandles: whiteRal,
  ralPlinth: whiteRal,
  ralTopPitch: whiteRal,
  currentPrintSide: 'Top',
  setCurrentPrintSide: () => null,
  updateCameraPosition: () => Promise.resolve(),
  printPanelIsOpen: false,
  setPrintPanelIsOpen: () => null,
  latestDesignFromServer: null,
  setLatestDesignFromServer: () => null,
  clearPersistedData: () => null,

  ralCode: 'Hvid',
  setRalCode: () => null,

  ralCodeLidActive: false,
  setRalCodeLidActive: () => null,
  ralCodeLid: 'Hvid',
  setRalCodeLid: () => null,

  ralCodeHandlesActive: false,
  setRalCodeHandlesActive: () => null,
  ralCodeHandles: 'Hvid',
  setRalCodeHandles: () => null,

  ralCodePlinthActive: false,
  setRalCodePlinthActive: () => null,
  ralCodePlinth: 'Hvid',
  setRalCodePlinth: () => null,

  ralCodeTopPitchActive: false,
  setRalCodeTopPitchActive: () => null,
  ralCodeTopPitch: 'Hvid',
  setRalCodeTopPitch: () => null,

  handles: 'greb',
  setHandles: () => null,
  handleCount: 6,
  setHandleCount: () => null,
  model: 'orbit',
  setModel: () => null,
  prints: {} as Prints,
  setPrints: () => null,
  updatePrints: () => null,

  // Persisted state
  activeDesignId: null,
  setActiveDesignId: () => null,
  cameraControlRef: { current: null },
}

export const CoffinEditorContext = createContext<State>(defaultValue)
export const useCoffinEditor = (): State => useContext(CoffinEditorContext)

type CoffinEditorProviderProps = {
  children: JSX.Element | JSX.Element[]
}

export const CoffinEditorProvider: FC<CoffinEditorProviderProps> = (p) => {
  // Temporary state
  const [activeTab, setActiveTab] = useState(defaultValue.activeTab)
  const [currentPrintSide, setCurrentPrintSide] = useState(
    defaultValue.currentPrintSide,
  )
  const [printPanelIsOpen, setPrintPanelIsOpen] = useState(
    defaultValue.printPanelIsOpen,
  )
  const [latestDesignFromServer, setLatestDesignFromServer] = useState(
    defaultValue.latestDesignFromServer,
  )
  const [ralCode, setRalCode] = useState(defaultValue.ralCode)

  const [ralCodeLidActive, setRalCodeLidActive] = useState(
    defaultValue.ralCodeLidActive,
  )
  const [ralCodeLid, setRalCodeLid] = useState(defaultValue.ralCodeLid)

  const [ralCodeHandlesActive, setRalCodeHandlesActive] = useState(
    defaultValue.ralCodeHandlesActive,
  )
  const [ralCodeHandles, setRalCodeHandles] = useState(
    defaultValue.ralCodeHandles,
  )

  const [ralCodePlinthActive, setRalCodePlinthActive] = useState(
    defaultValue.ralCodePlinthActive,
  )
  const [ralCodePlinth, setRalCodePlinth] = useState(defaultValue.ralCodePlinth)

  const [ralCodeTopPitchActive, setRalCodeTopPitchActive] = useState(
    defaultValue.ralCodeTopPitchActive,
  )
  const [ralCodeTopPitch, setRalCodeTopPitch] = useState(
    defaultValue.ralCodeTopPitch,
  )

  const [handles, setHandles] = useState(defaultValue.handles)
  const [handleCount, setHandleCount] = useState(defaultValue.handleCount)
  const [model, _setModel] = useState(defaultValue.model)
  const [prints, setPrints] = useState(defaultValue.prints)

  const cameraControlRef = useRef<CameraControls | null>(null)

  const [activeDesignId, setActiveDesignId] = useState<string | null>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const prevActiveDesignIdRef = useRef<string | null>(activeDesignId)

  useEffect(() => {
    const prevActiveDesignId = prevActiveDesignIdRef.current

    if (activeDesignId !== prevActiveDesignId) {
      if (activeDesignId) {
        // Update the search parameter in the URL when activeDesignId changes
        setSearchParams({ id: activeDesignId })
      } else {
        // Clear the id parameter if activeDesignId is null
        searchParams.delete('id')
        setSearchParams(searchParams)
      }
  
      // Update the ref to store the current activeDesignId
      prevActiveDesignIdRef.current = activeDesignId
    }
  }, [activeDesignId, setSearchParams, searchParams])

  const clearPersistedData = () => {
    setRalCode('Hvid')
    setRalCodeLidActive(false)
    setRalCodeLid('Hvid')
    setRalCodeHandlesActive(false)
    setRalCodeHandles('Hvid')
    setRalCodePlinthActive(false)
    setRalCodePlinth('Hvid')
    setRalCodeTopPitchActive(false)
    setRalCodeTopPitch('Hvid')
    setHandles('greb')
    setHandleCount(6)
    _setModel('orbit')
    setPrints(emptyPrints)
    setActiveDesignId(null)
    setLatestDesignFromServer(null)
  }

  const updateCameraPosition = async (position: [number, number, number]) => {
    await cameraControlRef?.current?.setPosition(...position, true)
  }

  const setModel = (model: Model) => {
    setHandleCount(6)
    setHandles('greb')
    _setModel(model)
  }

  const updatePrints = (printSide: PrintSide, print: Partial<Print>) => {
    setPrints((state) => ({
      ...state,
      [printSide]: {
        ...state[printSide],
        ...print,
      },
    }))
  }

  const getDesignFromPersistedId = useMemo(
    () => <GetDesignFromPersistedId />,
    [],
  )

  const limitStropperColor = (ral: Ral) => {
    if (
      handles === 'stropper' &&
      !['RAL 8001', 'RAL 9005', 'Hvid'].includes(ral.ral)
    ) {
      return whiteRal
    }
    return ral
  }

  return (
    <CoffinEditorContext.Provider
      value={{
        ralCode,
        ral: RAL_COLORS.find((color) => color.ral === ralCode) ?? whiteRal,
        setRalCode,

        ralLid: ralCodeLidActive
          ? RAL_COLORS.find((color) => color.ral === ralCodeLid) ?? whiteRal
          : RAL_COLORS.find((color) => color.ral === ralCode) ?? whiteRal,
        ralCodeLid,
        setRalCodeLid,
        ralCodeLidActive,
        setRalCodeLidActive,

        ralHandles: limitStropperColor(
          ralCodeHandlesActive
            ? RAL_COLORS.find((color) => color.ral === ralCodeHandles) ??
                whiteRal
            : RAL_COLORS.find((color) => color.ral === ralCode) ?? whiteRal,
        ),
        ralCodeHandles,
        setRalCodeHandles,
        ralCodeHandlesActive:
          handles === 'stropper' ? true : ralCodeHandlesActive,
        setRalCodeHandlesActive,

        ralPlinth: ralCodePlinthActive
          ? RAL_COLORS.find((color) => color.ral === ralCodePlinth) ?? whiteRal
          : RAL_COLORS.find((color) => color.ral === ralCode) ?? whiteRal,
        ralCodePlinth,
        setRalCodePlinth,
        ralCodePlinthActive,
        setRalCodePlinthActive,

        ralTopPitch: ralCodeTopPitchActive
          ? RAL_COLORS.find((color) => color.ral === ralCodeTopPitch) ??
            whiteRal
          : RAL_COLORS.find((color) => color.ral === ralCode) ?? whiteRal,
        ralCodeTopPitch,
        setRalCodeTopPitch,
        ralCodeTopPitchActive,
        setRalCodeTopPitchActive,

        activeTab,
        setActiveTab,
        handles,
        setHandles,
        handleCount,
        setHandleCount,
        model,
        setModel,
        currentPrintSide,
        setCurrentPrintSide,
        prints,
        setPrints,
        updatePrints,
        clearPersistedData,
        updateCameraPosition,
        printPanelIsOpen,
        setPrintPanelIsOpen,
        activeDesignId,
        setActiveDesignId,
        latestDesignFromServer,
        setLatestDesignFromServer,
        cameraControlRef,
      }}
    >
      {p.children}
      {getDesignFromPersistedId}
    </CoffinEditorContext.Provider>
  )
}
