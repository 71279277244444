import { cn } from '../../lib/utils'
import { Loader2 } from 'lucide-react'

export const Card = (p: {
  title: string
  imageUrl?: string
  isActive?: boolean
  onClick?: () => void
  children?: React.ReactNode
  footer?: React.ReactNode
  smallText?: boolean
  description?: string
  isLoading?: boolean
  hideTextOnSmallScreens?: boolean
}) => {
  return (
    <div
      className={cn(
        'group flex flex-col overflow-hidden rounded-md border',
        p.onClick && 'cursor-pointer',
      )}
      onClick={p.onClick}
    >
      {p.imageUrl && (
        <img className='aspect-video object-cover' src={p.imageUrl} alt={''} />
      )}
      {!p.imageUrl && p.children}
      <div
        className={cn(
          'flex items-center justify-center p-1 text-center text-sm',
          !(p.imageUrl ?? p.children) && 'py-4',
          p.onClick && 'group-hover:bg-accent',
          p.smallText && 'py-0.5 text-xs',
          p.description && ' bg-muted p-2 text-start font-medium',
          p.isActive &&
            'bg-foreground text-background group-hover:bg-foreground',
        )}
      >
        {p.title}
        {p.isLoading && <Loader2 className='ml-2 h-4 w-4 animate-spin' />}
      </div>
      {p.description && (
        <div
          className={cn(
            'whitespace-break-spaces p-2 text-sm opacity-60',
            p.hideTextOnSmallScreens && 'hidden lg:block',
          )}
        >
          {p.description}
        </div>
      )}
      {p.footer}
    </div>
  )
}
