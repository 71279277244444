import { useCoffinEditor } from '../../hooks/useCoffinEditor'
import { BooleanFilter } from '../../lib/boolean-filter'
import {
  RAL_COLORS,
  // SUGGESTED_RAL_COLORS,
  type RalCode,
  type Ral as RalType,
} from '../../lib/ral-colors'
import { X } from 'lucide-react'
import { useState } from 'react'

import { Button } from '../ui/button'
import { ColorCard } from './color-card'
import { Title } from './title'

type Area = 'all' | 'lid' | 'handles' | 'plinth' | 'topPitch'

export const Ral = (p: { close: () => void }) => {
  const [activeArea, setActiveArea] = useState<Area>('all')

  const {
    ral: ralAll,
    ralLid,
    ralHandles,
    ralPlinth,
    ralTopPitch,
    ralCode,
    setRalCode,

    ralCodeLid,
    ralCodeLidActive,
    setRalCodeLidActive,
    setRalCodeLid,

    ralCodeHandles,
    ralCodeHandlesActive,
    setRalCodeHandlesActive,
    setRalCodeHandles,

    ralCodePlinth,
    ralCodePlinthActive,
    setRalCodePlinthActive,
    setRalCodePlinth,

    ralCodeTopPitch,
    ralCodeTopPitchActive,
    setRalCodeTopPitchActive,
    setRalCodeTopPitch,

    handles,
    model,
  } = useCoffinEditor()

  const activeColor = {
    all: ralCode,
    lid: ralCodeLid,
    handles: ralCodeHandles,
    plinth: ralCodePlinth,
    topPitch: ralCodeTopPitch,
  }[activeArea] as RalCode

  const setActiveColor = (color: RalCode) => {
    switch (activeArea) {
      case 'all':
        setRalCode(color)
        break
      case 'lid':
        setRalCodeLidActive(true)
        setRalCodeLid(color)
        break
      case 'handles':
        setRalCodeHandlesActive(true)
        setRalCodeHandles(color)
        break
      case 'plinth':
        setRalCodePlinthActive(true)
        setRalCodePlinth(color)
        break
      case 'topPitch':
        setRalCodeTopPitchActive(true)
        setRalCodeTopPitch(color)
        break
    }
  }

  const isOrbit = model === 'orbit'

  const options = [
    {
      id: 'all',
      label: 'Grundfarve',
      ral: ralAll,
      isActive: true,
    },
    {
      id: 'lid',
      label: 'Lågtop',
      ral: ralLid,
      isActive: ralCodeLidActive,
      setActive: setRalCodeLidActive,
    },
    !isOrbit && {
      id: 'topPitch',
      label: 'Lågside',
      ral: ralTopPitch,
      isActive: ralCodeTopPitchActive,
      setActive: setRalCodeTopPitchActive,
    },
    !isOrbit && {
      id: 'plinth',
      label: 'Sokkel',
      ral: ralPlinth,
      isActive: ralCodePlinthActive,
      setActive: setRalCodePlinthActive,
    },
    {
      id: 'handles',
      label: 'Greb',
      ral: ralHandles,
      isActive: ralCodeHandlesActive,
      setActive: setRalCodeHandlesActive,
    },
  ].filter(Boolean) as {
    id: string
    label: string
    ral: RalType
    isActive: boolean
    setActive?: (active: boolean) => void
  }[]

  const showAsBaseColor = ['lid', 'handles', 'plinth', 'topPitch'].includes(
    activeArea,
  )

  const asBaseIsActive = {
    all: false,
    lid: !ralCodeLidActive,
    handles: !ralCodeHandlesActive,
    plinth: !ralCodePlinthActive,
    topPitch: !ralCodeTopPitchActive,
  }[activeArea]

  const activateAsBaseColor = {
    all: () => null,
    lid: () => setRalCodeLidActive(false),
    handles: () => setRalCodeHandlesActive(false),
    plinth: () => setRalCodePlinthActive(false),
    topPitch: () => setRalCodeTopPitchActive(false),
  }[activeArea]

  return (
    <div className='relative'>
      {!!options.length && (
        <div className='grid grid-cols-5 gap-1 p-2 lg:grid-cols-3'>
          {options.map(({ id, label, ral }) => (
            <div key={id} className='relative flex w-full'>
              <Button
                className='relative flex h-auto w-full flex-col justify-center p-2 pt-3 text-sm'
                variant={activeArea === id ? 'default' : 'ghost'}
                onClick={() => setActiveArea(id as Area)}
              >
                <div
                  className='mb-2 aspect-square max-h-8 min-h-8 rounded-sm border'
                  style={{
                    backgroundColor: ral?.hex,
                  }}
                />
                <div className='hidden text-[12px] font-normal lg:block lg:text-sm'>
                  {label}
                </div>
                <div className='block text-[12px] font-normal lg:hidden lg:text-sm'>
                  {label.replace('farve', '')}
                </div>
                <b className='text-[10px] lg:text-sm'>{ral.ral}</b>
              </Button>
            </div>
          ))}
        </div>
      )}
      <div className='max-h-[230px] w-full overflow-y-scroll p-3 lg:max-h-[400px]'>
        {showAsBaseColor && handles !== 'stropper' && (
          <div>
            <Title>Som grundfarve</Title>
            <div className='grid grid-cols-7 gap-0.5'>
              <ColorCard
                color={ralAll}
                isActive={asBaseIsActive}
                onClick={activateAsBaseColor}
              />
            </div>
          </div>
        )}
        {[
          // {
          //   title: 'Anbefalet farver',
          //   colors: SUGGESTED_RAL_COLORS,
          // },
          {
            title: 'Alle farver',
            colors: RAL_COLORS,
          },
        ]
          .filter(BooleanFilter)
          .map(({ title, colors }) => (
            <div key={title}>
              <Title>{title}</Title>
              <div className='grid grid-cols-7 gap-0.5'>
                {colors
                  .filter((color) => {
                    if (activeArea === 'handles' && handles === 'stropper') {
                      return ['Hvid', 'RAL 8001'].includes(color.ral)
                    } else {
                      return true
                    }
                  })
                  .map((color) => {
                    const isActive =
                      activeColor === color.ral && !asBaseIsActive

                    return (
                      <ColorCard
                        key={color.ral}
                        color={color}
                        isActive={isActive}
                        onClick={() => setActiveColor(color.ral)}
                      />
                    )
                  })}
              </div>
            </div>
          ))}
      </div>
      <div className='absolute -right-4 -top-4'>
        <Button
          variant='secondary'
          className='rounded-full border bg-background'
          size='icon'
          onClick={() => p.close()}
        >
          <X className='h-4 w-4' />
        </Button>
      </div>
    </div>
  )
}
