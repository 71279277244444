import { type FileItem } from '../components/file-uploader'
import { type UserInfo } from '../components/save-design-dialog'
import { BooleanFilter } from '../lib/boolean-filter'
import { useState } from 'react'
import { toast } from 'sonner'

import { designSchema, useApi, type Design } from './useApi'
import {
  emptyPrint,
  emptyPrints,
  useCoffinEditor,
  type Handle,
  type Print,
  type Prints,
  type PrintSide,
} from './useCoffinEditor'

export const useDesignCrud = () => {
  const [isSaving, setIsSaving] = useState(false)

  const {
    model,
    handles,
    handleCount,
    ral,
    prints,

    ralLid,
    ralHandles,
    ralPlinth,
    ralTopPitch,

    activeDesignId,
    latestDesignFromServer,
    setRalCode,

    setRalCodeLidActive,
    setRalCodeLid,

    setRalCodeHandlesActive,
    setRalCodeHandles,

    setRalCodePlinthActive,
    setRalCodePlinth,

    setRalCodeTopPitchActive,
    setRalCodeTopPitch,

    setHandles,
    setHandleCount,
    setModel,
    setPrints,
    setActiveDesignId,
    setLatestDesignFromServer,

    clearPersistedData,
  } = useCoffinEditor()
  const {
    uploadMedia,
    createDesign,
    updateDesign: updateDesignApi,
    getDesign: apiGetDesign,
    getMedia,
  } = useApi()

  const uploadPrints = async () => {
    return (
      await Promise.all(
        Object.entries(prints).map(async ([side, print]) => {
          if (!print) return null

          const shouldUpload =
            print?.fileItem?.state === 'not uploaded' && print?.fileItem?.file

          if (!shouldUpload) {
            return {
              side,
              ...print,
            }
          }

          const res = (await uploadMedia(print.fileItem!.file!)).data

          return {
            side,
            ...print,
            fileItem: {
              ...print.fileItem,
              id: res.fileid,
              url: res.url,
              name: res.filename,
              state: 'uploaded',
            } as FileItem,
          }
        }),
      )
    )
      .filter(BooleanFilter)
      .reduce(
        (acc, print) => {
          if (!print) return acc
          return {
            ...acc,
            [print.side]: print,
          }
        },
        {} as Record<PrintSide, Print>,
      )
  }

  const designPrints = (uploadedPrints: Record<PrintSide, Print>) => ({
    leftzoom: '' + uploadedPrints['Venstre side'].zoom ?? 0,
    leftoffsetx: '' + uploadedPrints['Venstre side'].offsetX ?? 0,
    leftoffsety: '' + uploadedPrints['Venstre side'].offsetY ?? 0,
    leftrotation: '' + uploadedPrints['Venstre side'].rotation ?? 0,
    leftmediafileid: '' + uploadedPrints['Venstre side'].fileItem?.id ?? '',
    leftmedianame: '' + uploadedPrints['Venstre side'].fileItem?.name ?? '',
    leftmediapxsize: '' + uploadedPrints['Venstre side'].fileItem?.size ?? '',
    leftmediasize: '' + uploadedPrints['Venstre side'].fileItem?.size ?? '',

    rightzoom: '' + uploadedPrints['Højre side'].zoom ?? 0,
    rightoffsetx: '' + uploadedPrints['Højre side'].offsetX ?? 0,
    rightoffsety: '' + uploadedPrints['Højre side'].offsetY ?? 0,
    rightrotation: '' + uploadedPrints['Højre side'].rotation ?? 0,
    rightmediafileid: '' + uploadedPrints['Højre side'].fileItem?.id ?? '',
    rightmedianame: '' + uploadedPrints['Højre side'].fileItem?.name ?? '',
    rightmediapxsize: '' + uploadedPrints['Højre side'].fileItem?.size ?? '',
    rightmediasize: '' + uploadedPrints['Højre side'].fileItem?.size ?? '',

    topzoom: '' + uploadedPrints.Top.zoom ?? 0,
    topoffsetx: '' + uploadedPrints.Top.offsetX ?? 0,
    topoffsety: '' + uploadedPrints.Top.offsetY ?? 0,
    toprotation: '' + uploadedPrints.Top.rotation ?? 0,
    topmediafileid: '' + uploadedPrints.Top.fileItem?.id ?? '',
    topmedianame: '' + uploadedPrints.Top.fileItem?.name ?? '',
    topmediapxsize: '' + uploadedPrints.Top.fileItem?.size ?? '',
    topmediasize: '' + uploadedPrints.Top.fileItem?.size ?? '',

    backzoom: '' + uploadedPrints.Bagside.zoom ?? 0,
    backoffsetx: '' + uploadedPrints.Bagside.offsetX ?? 0,
    backoffsety: '' + uploadedPrints.Bagside.offsetY ?? 0,
    backrotation: '' + uploadedPrints.Bagside.rotation ?? 0,
    backmediafileid: '' + uploadedPrints.Bagside.fileItem?.id ?? '',
    backmedianame: '' + uploadedPrints.Bagside.fileItem?.name ?? '',
    backmediapxsize: '' + uploadedPrints.Bagside.fileItem?.size ?? '',
    backmediasize: '' + uploadedPrints.Bagside.fileItem?.size ?? '',

    frontzoom: '' + uploadedPrints.Front.zoom ?? 0,
    frontoffsetx: '' + uploadedPrints.Front.offsetX ?? 0,
    frontoffsety: '' + uploadedPrints.Front.offsetY ?? 0,
    frontrotation: '' + uploadedPrints.Front.rotation ?? 0,
    frontmediafileid: '' + uploadedPrints.Front.fileItem?.id ?? '',
    frontmedianame: '' + uploadedPrints.Front.fileItem?.name ?? '',
    frontmediapxsize: '' + uploadedPrints.Front.fileItem?.size ?? '',
    frontmediasize: '' + uploadedPrints.Front.fileItem?.size ?? '',
  })

  const saveDesign = async (userInfo: UserInfo, cb?: () => void) => {
    let uploadedPrints = emptyPrints as Record<PrintSide, Print>

    try {
      uploadedPrints = await uploadPrints()
    } catch (error) {
      console.log(error)
      toast.error('Fejl: kunne ikke uploade billederne')
      return
    }

    const _handles = ['greb', 'stropper'].includes(handles)
      ? `${handleCount} ${handles}`
      : handles

    const design: Design = {
      name: userInfo.name,
      email: userInfo.email,
      phone: userInfo.phone,
      externalundertakerid: userInfo.externalundertakerid,
      model,
      handles: _handles,
      topcolor: ralLid.ral,
      handlecolor: ralHandles.ral,
      bottomcolor: ral.ral,
      plinthcolor: ralPlinth.ral,
      toppitchcolor: ralTopPitch.ral,
      ...designPrints({
        ...emptyPrints,
        ...uploadedPrints,
      }),
    }

    setIsSaving(true)
    try {
      const res = await createDesign(design)
      setActiveDesignId(res.data.designid)
      setLatestDesignFromServer(design)
      toast.success('Designet blev gemt')
      cb?.()
    } catch (error) {
      console.log(error)
      toast.error('Der opstod en fejl. Prøv venligst igen')
    }
    setIsSaving(false)
  }

  const updateDesign = async (
    { order, deliveryDate }: { order?: boolean, deliveryDate?: string },
    cb?: () => void,
  ) => {
    if (!activeDesignId) return

    let uploadedPrints = emptyPrints as Record<PrintSide, Print>
    try {
      uploadedPrints = await uploadPrints()
    } catch (error) {
      console.log(error)
      toast.error('Fejl: kunne ikke uploade billederne')
      return
    }

    const _handles = ['greb', 'stropper'].includes(handles)
      ? `${handleCount} ${handles}`
      : handles

    const design: Design = {
      ...latestDesignFromServer,
      model,
      deliverdatestring: deliveryDate,
      handles: _handles,
      topcolor: ralLid.ral,
      handlecolor: ralHandles.ral,
      bottomcolor: ral.ral,
      plinthcolor: ralPlinth.ral,
      toppitchcolor: ralTopPitch.ral,
      ...designPrints(uploadedPrints),
    }

    setIsSaving(true)
    try {
      const res = await updateDesignApi(activeDesignId, design, order)
      setActiveDesignId(res.data.designid)
      setLatestDesignFromServer(design)
      toast.success(
        order ? 'Dit design er blevet bestilt' : 'Designet er blevet opdateret',
      )
      cb?.()
    } catch (error) {
      console.log(error)
      toast.error('Der opstod en fejl. Prøv venligst igen')
    }
    setIsSaving(false)
  }

  const getDesign = async (designId: string, isTemplate = false) => {
    try {
      let design = await apiGetDesign(designId)

      try {
        const parsedDesign = designSchema.parse(design)
        if (parsedDesign) {
          design = parsedDesign as Design
        }
      } catch (e) {
        console.log('error', e)
        toast.error('Designet kunne ikke hentes')
        clearPersistedData()
      }

      !isTemplate && setLatestDesignFromServer(design)

      setModel(design.model ?? 'orbit')
      const handleParts = (design.handles ?? '6 greb').split(' ')

      setHandles(
        handleParts.length === 1
          ? (handleParts[0] as Handle)
          : handleParts.length === 2 && handleParts[1]
            ? (handleParts[1] as Handle)
            : 'greb',
      )
      setHandleCount(
        handleParts.length === 1
          ? 6
          : handleParts[0]
            ? parseInt(handleParts[0], 10)
            : 6,
      )

      setRalCode(design.bottomcolor ?? 'Hvid')

      const ralCodeLidActive =
        design.topcolor && design.topcolor !== design.bottomcolor
      setRalCodeLidActive(ralCodeLidActive)
      setRalCodeLid(design.topcolor)

      const ralCodeHandlesActive =
        design.handlecolor && design.handlecolor !== design.bottomcolor
      setRalCodeHandlesActive(ralCodeHandlesActive)
      setRalCodeHandles(design.handlecolor)

      const ralCodePlinthActive =
        design.plinthcolor && design.plinthcolor !== design.bottomcolor
      setRalCodePlinthActive(ralCodePlinthActive)
      setRalCodePlinth(design.plinthcolor)

      const ralCodeTopPitchActive =
        design.toppitchcolor && design.toppitchcolor !== design.bottomcolor
      setRalCodeTopPitchActive(ralCodeTopPitchActive)
      setRalCodeTopPitch(design.toppitchcolor)

      const prints: Prints = [
        {
          side: 'Top',
          apiName: 'top',
        },
        {
          side: 'Venstre side',
          apiName: 'left',
        },
        {
          side: 'Højre side',
          apiName: 'right',
        },
        {
          side: 'Front',
          apiName: 'front',
        },
        {
          side: 'Bagside',
          apiName: 'back',
        },
      ].reduce(
        (acc, { side, apiName }) => {
          type DesignKey = keyof typeof design

          const fileId = design[`${apiName}mediafileid` as DesignKey] as
            | string
            | undefined

          const fileSize = design[`${apiName}mediasize` as DesignKey] as
            | string
            | undefined

          const filename = design[`${apiName}medianame` as DesignKey] as
            | string
            | undefined

          if (!fileId || !design) {
            acc[side as PrintSide] = emptyPrint
            return acc
          }

          acc[side as PrintSide] = {
            fileItem:
              !!fileId && fileId !== 'undefined'
                ? {
                    id: fileId,
                    thumbnailUrl: getMedia(fileId + 'T'),
                    previewUrl: getMedia(fileId + 'P'),
                    url: getMedia(fileId),
                    state: 'uploaded',
                    size: +(fileSize ?? '0'),
                    name: filename ?? 'unknown',
                  }
                : undefined,
            zoom: +(design[`${apiName}zoom` as DesignKey] ?? '0.5'),
            offsetX: +(design[`${apiName}offsetx` as DesignKey] ?? '0'),
            offsetY: +(design[`${apiName}offsety` as DesignKey] ?? '0'),
            rotation: +(design[`${apiName}rotation` as DesignKey] ?? '0'),
          }
          return acc
        },
        {} as Record<PrintSide, Print>,
      )

      setPrints(prints)
      !isTemplate && setActiveDesignId(designId)
      return true
    } catch (error) {
      console.log(error)
      toast.error('Kunne ikke hente design')
      return false
    }
  }

  return {
    saveDesign,
    isSavingDesign: isSaving,
    updateDesign,
    getDesign,
  }
}
