import { type PrintSide, useCoffinEditor } from '../../hooks/useCoffinEditor'
import { cn } from '../../lib/utils'
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  RotateCcw,
  RotateCw,
} from 'lucide-react'
import { useCallback, useEffect, useState } from 'react'

import { Button } from '../ui/button'
import { Slider } from '../ui/slider'
import { ImageUpload } from './image-upload'
import { Title } from './title'
import { ImageArchiveModal, type ImageTypes } from './image-archive-modal'

interface Props {
  closePanel: () => void
}

export const Print = ({ closePanel }: Props) => {
  const { currentPrintSide, updatePrints, prints, latestDesignFromServer } =
    useCoffinEditor()
  const currentPrint = prints[currentPrintSide]
  const [imageSize, setImageSize] = useState([0, 0])
  const [activeMode, setActiveMode] = useState<
    'none' | 'size' | 'offsetX' | 'offsetY' | 'rotate'
  >('none')
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(window.innerWidth < 1024)
    const resizeListener = () => {
      setIsMobile(window.innerWidth < 1024)
    }

    window.addEventListener('resize', resizeListener)

    return () => window.removeEventListener('resize', resizeListener)
  }, [])

  const getImageSize = useCallback(async (): Promise<[number, number]> => {
    return new Promise((resolve, reject) => {
      if (!currentPrint?.fileItem?.url) return
      const img = new Image()
      try {
        img.src = currentPrint?.fileItem?.url
        img.onload = () => {
          resolve([img.width, img.height])
        }
        img.onerror = (e) => {
          console.log(e)
          reject()
        }
      } catch (e) {
        console.log(e)
      }
    })
  }, [currentPrint?.fileItem?.url])

  useEffect(() => {
    if (!currentPrint?.fileItem?.url) {
      setImageSize([0, 0])
      return
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getImageSize().then((res) => setImageSize(res))
  }, [currentPrint?.fileItem?.url, getImageSize])

  // Default width is at the moment 48 cm / default zoom is 0.5
  const widthPixels = imageSize[0] ?? 0
  const zoomCm = (currentPrint?.zoom ?? 0.5) * (48 / 0.5)
  const pixelDensity = widthPixels / zoomCm

  const isReadonly = !!latestDesignFromServer?.readonly

  const imageQuality = useCallback(() => {
    if (!pixelDensity) {
      return { emoji: '', message: '' }
    }
    if (pixelDensity < 24) {
      return {
        emoji: '🔴',
        message: 'Opløsningen er for lille til at blive printet',
      }
    } else if (pixelDensity < 32) {
      return { emoji: '🟡', message: 'Risiko for at billedet bliver grynet' }
    } else {
      return { emoji: '🟢', message: '' }
    }
  }, [pixelDensity])

  const sideType: Record<PrintSide, ImageTypes> = {
    'Top': 'topready',
    'Venstre side': 'sideready',
    'Højre side': 'sideready',
    'Front': 'gableready',
    'Bagside': 'gableready',
  };

  const [isImageArchiveModalOpen, setIsImageArchiveModalOpen] = useState(false)

  return (
    <div className='max-h-[80vh] overflow-scroll p-3 pt-6 lg:pt-3'>
      <ImageArchiveModal type={sideType[currentPrintSide]} isOpen={isImageArchiveModalOpen} setIsOpen={setIsImageArchiveModalOpen} />
      {(!isMobile || activeMode === 'none') && (
        <>
          <Title className='text-lg font-bold'>{currentPrintSide}</Title>
          <Title className='mb-1 font-normal'>Vælg billede</Title>
          <ImageUpload readonly={isReadonly} />
          <div className="flex items-center mt-2">
            <div className="w-full h-[1px] bg-gray-100"></div>
            <Button className="block w-full mx-4 !text-xs !font-normal" size="sm" variant="outline" onClick={() => setIsImageArchiveModalOpen(!isImageArchiveModalOpen)}>
              Vælg et billede fra vores arkiv
            </Button>
            <div className="w-full h-[1px] bg-gray-100"></div>
          </div>
          <div className='mt-3 text-xs opacity-50'>
            Billedets placering er vejledende og vil blive behandlet af en
            medarbejder, når det skal trykkes. Ønskes flere billeder på én side,
            kontakt os venligst.
          </div>
        </>
      )}

      <div className={cn(isReadonly && 'pointer-events-none opacity-50')}>
        {isMobile && activeMode === 'none' && (
          <>
            <div className="flex items-center mt-2 mb-2">
              <div className="w-full h-[1px] bg-gray-100"></div>
              <div className="mx-4 text-sm text-gray-800 whitespace-nowrap">Vælg justeringsmulighed</div>
              <div className="w-full h-[1px] bg-gray-100"></div>
            </div>
            <div className='grid grid-cols-2 gap-1'>
              <Button
                className='block w-full'
                variant='outline'
                onClick={() => setActiveMode('size')}
              >
                Størrelse på billede
              </Button>
              <Button
                className='block w-full'
                variant='outline'
                onClick={() => setActiveMode('rotate')}
              >
                Rotér
              </Button>
              <Button
                className='block w-full'
                variant='outline'
                onClick={() => setActiveMode('offsetY')}
              >
                Forskyd ned/op
              </Button>
              <Button
                className='block w-full'
                variant='outline'
                onClick={() => setActiveMode('offsetX')}
              >
                Forskyd venstre/højre
              </Button>
            </div>
            <div className="flex items-center mt-2 mb-2">
              <div className="w-full h-[1px] bg-gray-100"></div>
              <div className="mx-4 text-sm text-gray-500">eller</div>
              <div className="w-full h-[1px] bg-gray-100"></div>
            </div>
          </>
        )}

        {(!isMobile || activeMode === 'size') && (
          <>
            <div className='mt-3 text-xs'>
              Billedekvalitet: {imageQuality().emoji} (
              {Math.floor(pixelDensity)}px / cm) {imageQuality().message}
            </div>
            <Title
              className='mb-2 mt-4 font-normal'
              value={`${((currentPrint?.zoom ?? 0) * 100)?.toFixed(0)}%`}
            >
              Størrelse på billede
            </Title>
            <Slider
              value={[(currentPrint?.zoom ?? 1) * 100 ?? 50]}
              max={400}
              step={1}
              onValueChange={(value) => {
                updatePrints(currentPrintSide, {
                  zoom: (value[0] ?? 100) / 100,
                })
              }}
              onThumbDoubleClick={() => {
                updatePrints(currentPrintSide, {
                  zoom: 0.5,
                })
              }}
            />
          </>
        )}

        {(!isMobile || activeMode === 'offsetX') && (
          <>
            <Title
              className='mb-2 mt-4 font-normal'
              value={((currentPrint?.offsetX ?? 0) * 100)?.toFixed(0)}
            >
              Forskyd venstre/højre
              <ChevronLeft className='ml-2 h-4 w-4 opacity-50' />
              <ChevronRight className='h-4 w-4 opacity-50' />
            </Title>
            <Slider
              rangeClassName='bg-secondary'
              value={[(currentPrint?.offsetX ?? 0) * 100 ?? 0]}
              max={100}
              min={-100}
              step={1}
              onValueChange={(value) => {
                updatePrints(currentPrintSide, {
                  offsetX: (value[0] ?? 100) / 100,
                })
              }}
              onThumbDoubleClick={() => {
                updatePrints(currentPrintSide, {
                  offsetX: 0,
                })
              }}
            />
          </>
        )}

        {(!isMobile || activeMode === 'offsetY') && (
          <>
            <Title
              className='mb-2 mt-4 font-normal'
              value={((currentPrint?.offsetY ?? 0) * 100)?.toFixed(0)}
            >
              Forskyd ned/op
              <ChevronDown className='ml-2 h-4 w-4 opacity-50' />
              <ChevronUp className='h-4 w-4 opacity-50' />
            </Title>
            <Slider
              rangeClassName='bg-secondary'
              value={[(currentPrint?.offsetY ?? 0) * 100 ?? 0]}
              max={100}
              min={-100}
              step={1}
              onValueChange={(value) => {
                updatePrints(currentPrintSide, {
                  offsetY: (value[0] ?? 100) / 100,
                })
              }}
              onThumbDoubleClick={() => {
                updatePrints(currentPrintSide, {
                  offsetY: 0,
                })
              }}
            />
          </>
        )}

        {(!isMobile || activeMode === 'rotate') && (
          <>
            <Title
              className='mb-2 mt-4 font-normal'
              value={['0°', '90°', '180°', '-90°'][currentPrint?.rotation ?? 0]}
            >
              Rotér
            </Title>
            <div className='flex gap-2'>
              <Button
                size='sm'
                variant='secondary'
                className='w-full'
                onClick={() => {
                  updatePrints(currentPrintSide, {
                    rotation: ((currentPrint?.rotation ?? 0) + 1) % 4,
                  })
                }}
              >
                <RotateCcw className='h-4 w-4' />
              </Button>
              <Button
                size='sm'
                variant='secondary'
                className='w-full'
                onClick={() => {
                  updatePrints(currentPrintSide, {
                    rotation: ((currentPrint?.rotation ?? 0) - 1 + 4) % 4,
                  })
                }}
              >
                <RotateCw className='h-4 w-4' />
              </Button>
            </div>
          </>
        )}

        {activeMode !== 'none' && (
          <Button
            variant='outline'
            className='mt-6 block w-full'
            onClick={() => {
              setActiveMode('none')
            }}
          >
            Tilbage
          </Button>
        )}

        <Button className='w-full' onClick={() => closePanel()}>
          Luk panel
        </Button>
      </div>
    </div>
  )
}
