import { emptyPrint, useCoffinEditor } from '../../hooks/useCoffinEditor'

import { FileUploader, type FileItem } from '../file-uploader'

export const ImageUpload = (p: { readonly?: boolean }) => {
  const { currentPrintSide, prints, updatePrints } = useCoffinEditor()

  const currentPrint = prints[currentPrintSide]

  const onChange = async (fileItems: FileItem[]) => {
    const fileItem = fileItems[0]

    if (!fileItem?.file) {
      // Delete file
      updatePrints(currentPrintSide, emptyPrint)
      return
    }

    const updatedPrint = {
      ...emptyPrint,
      fileItem: {
        ...fileItem,
        previewUrl: fileItem.url,
        thumbnailUrl: fileItem.url,
        url: fileItem.url,
        name: fileItem.file.name,
        size: fileItem.file.size,
        mimeType: fileItem.file.type,
      } as FileItem,
    }

    updatePrints(currentPrintSide, updatedPrint)
  }

  const fileItems: FileItem[] = [
    {
      ...currentPrint?.fileItem,
      url: currentPrint?.fileItem?.url ?? currentPrint?.fileItem?.previewUrl,
      previewUrl:
        currentPrint?.fileItem?.previewUrl ?? currentPrint?.fileItem?.url,
      thumbnailUrl:
        currentPrint?.fileItem?.thumbnailUrl ??
        currentPrint?.fileItem?.previewUrl ??
        currentPrint?.fileItem?.url,
    },
  ].filter((v) => !!v.url) as FileItem[]

  return (
    <FileUploader
      maxFileSize={50 * 1024 * 1024}
      fileItems={fileItems}
      supportedMimeTypes={['image/jpeg', 'image/png']}
      supportedFilesText='JPEG, PNG'
      onChange={onChange}
      readonly={p.readonly}
    />
  )
}
