import { useCoffinEditor } from '../../hooks/useCoffinEditor'
import { cn } from '../../lib/utils'
import { X } from 'lucide-react'

import { Button } from '../ui/button'
import { Print } from './print'

export const ImagePanel = () => {
  const { printPanelIsOpen, setPrintPanelIsOpen } = useCoffinEditor()

  return (
    <div
      className={cn(
        'absolute right-0 top-10 z-50 min-w-96 max-w-96 rounded-l-xl bg-background shadow-lg transition slide-in-from-right-2 lg:top-32',
        printPanelIsOpen && 'translate-x-0 opacity-100',
        !printPanelIsOpen && 'pointer-events-none translate-x-2 opacity-0',
      )}
    >
      <Print closePanel={() => setPrintPanelIsOpen(false)} />
      <div className='absolute right-1 top-1'>
        <Button
          variant='ghost'
          size='icon'
          onClick={() => setPrintPanelIsOpen(false)}
        >
          <X className='h-4 w-4' />
        </Button>
      </div>
    </div>
  )
}
