import { type FileItem } from '.'
import { filesize } from 'filesize'
import { BooleanFilter } from '../../lib/boolean-filter'
import { capitalize } from 'lodash'
import { DownloadCloud, Loader2, Replace, Trash } from 'lucide-react'
import { useCallback } from 'react'
import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from '../../components/ui/tooltip'

export type FileUploadState = 'uploaded' | 'not uploaded' | 'error' | 'archive'

type Props = {
  fileItem: FileItem
  deleteItem: () => void
  replaceItem: () => void
  downloadFile?: () => void
  isLast?: boolean
  readonly?: boolean
}

export const FileUploaderItem = (p: Props) => {
  const toFileSize = (size: number) => {
    return filesize(size || 0, {
      base: 2,
      standard: 'jedec',
    })
  }

  const tag = {
    uploaded: {
      text: 'Uploaded',
      color: 'text-green-500',
    },
    'not uploaded': {
      text: 'Not uploaded',
      color: 'text-orange-500',
    },
    'archive': {
      text: 'Arkiv',
      color: 'text-orange-500',
    },
    error: {
      text: 'Error',
      color: 'text-red-500',
    },
  }[p.fileItem.state ?? 'not uploaded'] || {
    text: 'To be uploaded',
    color: 'text-yellow-500',
  }

  const fileSize = p.fileItem.size ?? p.fileItem.file?.size ?? 0

  const ImagePreview = useCallback(
    () => (
      <img
        src={p.fileItem.thumbnailUrl}
        className='h-full w-full object-cover'
        alt=''
      />
    ),
    [p.fileItem.thumbnailUrl],
  )
  const Errors = () => {
    if (!p.fileItem.errors?.length) return null
    return (
      <div className='-mt-1 text-sm text-red-500'>
        {capitalize(p.fileItem.errors.join(', ').toLocaleLowerCase())}
      </div>
    )
  }

  const Preview = () => (
    <div className='relative h-20 min-w-28 max-w-28 overflow-hidden rounded-md border'>
      <ImagePreview />
      <div className='absolute bottom-1 left-1 flex items-center rounded-full bg-white/50 px-1.5 text-xs text-black backdrop-blur-md'>
        {p.fileItem.percentage ? (
          <>
            ${p.fileItem.percentage.toFixed(0)}%{' '}
            <Loader2 className='h-3 w-3 animate-spin' />
          </>
        ) : (
          tag.text
        )}
      </div>
    </div>
  )

  return (
    <div>
      <div className='flex w-full min-w-full overflow-hidden rounded-md bg-background'>
        <Preview />
        <div className='relative min-w-0 px-2'>
          <div className='line-clamp-1 text-ellipsis font-bold'>
            {p.fileItem.name ?? p.fileItem.file?.name}
          </div>
          {!!fileSize && (
            <p className='-mt-1 line-clamp-1 text-ellipsis text-sm opacity-50'>
              {p.fileItem.mimeType} - {toFileSize(fileSize)}
            </p>
          )}
          <Errors />
          
              <div className='absolute bottom-1 left-2 flex'>
                {[
                  !p.readonly && {
                    ariaLabel: 'Skift',
                    icon: <Replace className='h-4 w-4' />,
                    onClick: p.replaceItem,
                  },
                  {
                    ariaLabel: 'Download',
                    icon: <DownloadCloud className='h-4 w-4' />,
                    onClick: p.downloadFile,
                  },
                  !p.readonly && {
                    ariaLabel: 'Slet',
                    icon: <Trash className='h-4 w-4' />,
                    onClick: p.deleteItem,
                  },
                ]
                  .filter(BooleanFilter)
                  .map((item, i) => (
                    <TooltipProvider delayDuration={0}>
                      <Tooltip>
                        <TooltipTrigger className="mr-2">
                          <div
                            key={i}
                            className='cursor-pointer rounded p-1 text-sm hover:bg-muted'
                            onClick={item.onClick}
                            aria-label={item.ariaLabel}
                          >
                            {item.icon}
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>{item.ariaLabel}</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  ))}
              </div>
        </div>
      </div>
      {!p.isLast && <div className='h-1' />}
    </div>
  )
}
