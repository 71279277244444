import { cn } from '../../lib/utils'

export const Title = (p: {
  children: React.ReactNode
  className?: string
  value?: string
}) => {
  return (
    <h2
      className={cn(
        'mb-1 flex justify-between gap-2 text-sm font-medium',
        p.className,
      )}
    >
      <div className='flex items-center'>{p.children}</div>
      {p.value && <div className='flex justify-end opacity-50'>{p.value}</div>}
    </h2>
  )
}
