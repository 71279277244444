import React, { Suspense } from 'react'

import CoffinViewer from './components/coffin-viewer'
import { CurrentDesign } from './components/current-design'
import { ImagePanel } from './components/panel/image-panel'
import { Menu } from './components/panel/menu'
import { QuickOrientation } from './components/quick-orientation'
import { Toaster } from './components/ui/sonner'
import { CoffinEditorProvider } from './hooks/useCoffinEditor'
import { ConfirmProvider } from './hooks/useConfirm'
import { UseQueryProvider } from './providers/UseQueryProvider'

function App() {
  return (
    <UseQueryProvider>
      <ConfirmProvider>
        <CoffinEditorProvider>
          <main className='relative flex min-h-screen overflow-hidden bg-zinc-300'>
            <CoffinViewer />
            <QuickOrientation />
            <Menu />
            <ImagePanel />
            <Suspense fallback={null}>
              <CurrentDesign />
            </Suspense>
          </main>
        </CoffinEditorProvider>
        <Toaster />
      </ConfirmProvider>
    </UseQueryProvider>
  )
}

export default App
