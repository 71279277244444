import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useCoffinEditor } from '../hooks/useCoffinEditor'
import { useDesignCrud } from '../hooks/useDesignCrud'

export const GetDesignFromPersistedId = () => {
  const { getDesign } = useDesignCrud()
  const { setActiveDesignId } = useCoffinEditor()
  const [searchParams, setSearchParams] = useSearchParams()

  const paramId = searchParams.get('id')
  useEffect(() => {
    setActiveDesignId(paramId)
    setSearchParams(searchParams)
  }, [paramId])

  useEffect(() => {
    if (paramId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getDesign(paramId)
    }
  }, [paramId])

  return null
}
