'use client'

import { useQuery } from '@tanstack/react-query'
import { type Undertaker, useApi } from '../hooks/useApi'
import { useEffect, useState } from 'react'
import Select, { type SingleValue } from 'react-select';

interface Option {
  value: number
  label: string
  data: Undertaker
}

export function UndertakerSearch(p: {
  externalundertakerid: number | undefined
  setExternalUndertakerId: (id: number) => void
}) {
  const { getUndertakers } = useApi()

  const { data: undertakers } = useQuery({
    queryKey: ['undertakers'],
    queryFn: getUndertakers,
  })

  const formatOptionLabel = ({ data }: Option) => (
    <div className='text-sm'>
        <b>{data.name}</b>
        <div className='text-xs'>
          {data.address}, {data.zipcity}
        </div>
        <div className='text-xs'>Tlf: {data.phone}</div>
      </div>
  );

  const options = undertakers?.results.map((undertaker) => ({
    value: undertaker.id,
    label: undertaker.name + ' ' + undertaker.address + ' ' + undertaker.zip + ' ' + undertaker.zipcity,
    data: undertaker
  }))

  const [chosenUndertaker, setChosenUndertaker] = useState<Option>();
  useEffect(() => {
    if (options && p.externalundertakerid) {
      const option = options.find((option) => option.value === p.externalundertakerid)
      if (option) {
        setChosenUndertaker(option);
      }
    }
  }, [])

  const onChangeHandler = (option: SingleValue<Option>) => {
    if (option?.value) {
      p.setExternalUndertakerId(option.value)
    }
  }

  if (!undertakers?.isthisneeded) return null

  return (
    <Select
      className="basic-single"
      classNamePrefix="select"
      defaultValue={chosenUndertaker}
      formatOptionLabel={formatOptionLabel}
      isSearchable
      name="undertakers"
      options={options}
      onChange={onChangeHandler}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? '#94a3b8' : '#e2e8f0',
          boxShadow: state.isFocused ? '0 0 0 1px #94a3b8' : 'none',
          minHeight: '40px'
        }),
      }}
      theme={(theme) => ({
        ...theme,
        borderRadius: 5,
        borderColor: '#94a3b8',
        colors: {
          ...theme.colors,
          primary25: '#f4f4f4',
          primary: 'black',
        },
      })}
    />
  )
}
