import { type Handle, type Model } from './useCoffinEditor'

const baseUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://cdesigner.nembedemand.dk/static'

const empty = `${baseUrl}/empty.obj`

const useOrbit = () => {
  const orbitLid = `${baseUrl}/orbit_lid.obj`
  const orbitBack = `${baseUrl}/orbit_back.obj`
  const orbitFront = `${baseUrl}/orbit_front.obj`
  const orbitLeft = `${baseUrl}/orbit_left.obj`
  const orbitRight = `${baseUrl}/orbit_right.obj`
  const orbitRest = `${baseUrl}/orbit_rest.obj`
  const orbitTop = `${baseUrl}/orbit_top.obj`

  return {
    orbitLid,
    orbitBack,
    orbitFront,
    orbitLeft,
    orbitRight,
    orbitRest,
    orbitTop,
    // orbitGreb6,
  }
}

const useFlex = () => {
  const flexTopPitch = `${baseUrl}/flex_top_pitch_no_lid.obj`
  const flexLid = `${baseUrl}/flex_lid_edge.obj`
  const flexBack = `${baseUrl}/flex_back-2.obj`
  const flexFront = `${baseUrl}/flex_front-2.obj`
  const flexLeft = `${baseUrl}/flex_left-2.obj`
  const flexRight = `${baseUrl}/flex_right-2.obj`
  const flexTop = `${baseUrl}/flex_top.obj`
  const flexPlinth = `${baseUrl}/flex_plinth-2.obj`
  const flexStropper6 = `${baseUrl}/flex_stropper_6_low_res.obj`
  const flexStropper10 = `${baseUrl}/flex_stropper_10_low_res.obj`

  return {
    flexTopPitch,
    flexLid,
    flexBack,
    flexFront,
    flexLeft,
    flexRight,
    flexTop,
    flexPlinth,
    flexStropper6,
    flexStropper10,
  }
}

const useLigb = () => {
  const ligbTopPitch = `${baseUrl}/ligb_top_pitch_no_lid.obj`
  const ligbLid = `${baseUrl}/ligb_lid_edge.obj`
  const ligbTop = `${baseUrl}/ligb_top.obj`

  return {
    ligbTopPitch,
    ligbLid,
    ligbTop,
  }
}

export const useObj = (
  model: Model,
  _handles: Handle,
  handleCount: number,
): {
  top: string
  lid: string
  topPitch: string
  rest: string
  left: string
  right: string
  front: string
  back: string
  plinth: string
  handles: string
  greb1: string
  greb1end: string
  baerestaenger: string
} => {
  const orbit = useOrbit()
  const flex = useFlex()
  const ligb = useLigb()

  const baerestaenger = `${baseUrl}/baerestaenger.obj`
  const greb1 = `${baseUrl}/greb_1.obj`
  const greb1end = `${baseUrl}/greb_1_end.obj`

  if (model === 'orbit') {
    return {
      top: orbit.orbitTop,
      lid: orbit.orbitLid,
      topPitch: empty,
      rest: orbit.orbitRest,
      left: orbit.orbitLeft,
      right: orbit.orbitRight,
      front: orbit.orbitFront,
      back: orbit.orbitBack,
      plinth: empty,
      handles: empty,
      greb1,
      greb1end,
      baerestaenger,
    }
  }

  const handles =
    {
      greb: null,
      stropper:
        {
          6: flex.flexStropper6,
          10: flex.flexStropper10,
        }[handleCount] ?? empty,
      baerestaenger,
    }[_handles] ?? empty

  if (model === 'flex') {
    return {
      top: flex.flexTop,
      lid: flex.flexLid,
      topPitch: flex.flexTopPitch,
      rest: empty,
      left: flex.flexLeft,
      right: flex.flexRight,
      front: flex.flexFront,
      back: flex.flexBack,
      plinth: flex.flexPlinth,
      handles,
      greb1,
      greb1end,
      baerestaenger,
    }
  }

  if (model === 'ligb') {
    return {
      top: ligb.ligbTop,
      lid: ligb.ligbLid,
      topPitch: ligb.ligbTopPitch,
      rest: empty,
      left: flex.flexLeft,
      right: flex.flexRight,
      front: flex.flexFront,
      back: flex.flexBack,
      plinth: flex.flexPlinth,
      handles,
      greb1,
      greb1end,
      baerestaenger,
    }
  }

  return {
    top: empty,
    lid: empty,
    topPitch: empty,
    rest: empty,
    left: empty,
    right: empty,
    front: empty,
    back: empty,
    plinth: empty,
    handles: empty,
    greb1: empty,
    greb1end: empty,
    baerestaenger: empty,
  }
}
