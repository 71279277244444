import { useQuery } from '@tanstack/react-query'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../components/ui/alert-dialog'
import { Popover, PopoverContent, PopoverTrigger } from '../components/ui/popover'
import { Calendar } from '../components/ui/calendar'
import { Table, TableBody, TableCell, TableRow } from '../components/ui/table'
import { useApi } from '../hooks/useApi'
import { useCoffinEditor } from '../hooks/useCoffinEditor'
import { useConfirm } from '../hooks/useConfirm'
import { useDesignCrud } from '../hooks/useDesignCrud'
import { CalendarIcon } from "lucide-react"
import { Button } from './ui/button'
import { useState } from 'react'
import { Input } from './ui/input'
import { format, parse } from 'date-fns'

export const OrderDesignDialog = (p: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}) => {
  const { getUndertakers } = useApi()

  const { data: undertakers } = useQuery({
    queryKey: ['undertakers'],
    queryFn: getUndertakers,
  })

  const { latestDesignFromServer, activeDesignId } = useCoffinEditor()
  const { confirm } = useConfirm()

  const { updateDesign, isSavingDesign, getDesign } = useDesignCrud()

  const onOrderClick = async () => {
    confirm({
      title: 'Indsend design',
      message: `Vi vil tage kontakt til dig hurtigst muligt, når vi har modtaget dit design. 

      Vil du indsende det nu?`,
      confirmText: 'Indsend',
      onConfirm: async (cb) => {
        await updateDesign({ order: true, deliveryDate }, () => p.setIsOpen(false))
        activeDesignId && (await getDesign(activeDesignId))
        cb()
      },
    })
  }

  const selectedUndertaker = undertakers?.results.find(
    (u) => u.id === latestDesignFromServer?.externalundertakerid,
  )

  const [deliveryDate, setDeliveryDate] = useState<string | undefined>()
  const [showCalendar, setShowCalendar] = useState(false)

  return (
    <AlertDialog open={p.isOpen} onOpenChange={p.setIsOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Indsend</AlertDialogTitle>
          <AlertDialogDescription>
            <div className='mb-4'>
              Bekræft venligst dine oplysninger og design. Vi vil kontakte dig
              hurtigst muligt, når du har indsendt.
            </div>
            <Table className='mb-4'>
              <TableBody>
                <TableRow>
                  <TableCell className='font-bold'>Navn</TableCell>
                  <TableCell>{latestDesignFromServer?.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className='font-bold'>Email</TableCell>
                  <TableCell>{latestDesignFromServer?.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className='font-bold'>Telefonnummer</TableCell>
                  <TableCell>{latestDesignFromServer?.phone}</TableCell>
                </TableRow>
                {undertakers?.isthisneeded && (
                  <TableRow>
                    <TableCell className='font-bold'>Bedemand</TableCell>
                    <TableCell>
                      {selectedUndertaker?.name ?? 'Ikke fundet'}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell className='font-bold leading-tight w-56'>Dato for levering af kiste<br /><small className="font-normal leading-3">Hvis du kender datoen for hvornår kisten skal bruges, kan du angive den her</small></TableCell>
                  <TableCell>
                    <div className="flex items-center">
                      <Input
                        value={deliveryDate}
                        inputSize="sm"
                        onChange={(e) => setDeliveryDate(e.target.value)}
                      />
                      <Popover open={showCalendar} onOpenChange={setShowCalendar}>
                        <PopoverTrigger asChild>
                          <Button
                            variant='outline'
                            size="sm"
                            className="ml-2"
                            onClick={() => setShowCalendar(true)}
                          >
                            <CalendarIcon className='w-4 h-4 opacity-50' />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                          <Calendar
                            mode="single"
                            selected={deliveryDate ? parse(deliveryDate, 'dd-MM-yyyy', new Date()) : undefined}
                            onSelect={(date) => {
                              setDeliveryDate(date ? format(date, 'dd-MM-yyyy') : '')
                              setShowCalendar(false)
                            }}
                            disabled={(date) => date < new Date()}
                            initialFocus
                          />
                        </PopoverContent>
                      </Popover>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Annuller</AlertDialogCancel>
          <Button loading={isSavingDesign} onClick={onOrderClick}>
            Bestil
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
