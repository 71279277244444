import { useCoffinEditor } from '../hooks/useCoffinEditor'
import { cn } from '../lib/utils'
import { ZoomIn, ZoomOut } from 'lucide-react'

import {
  BACK_POSITION,
  FRONT_POSITION,
  LEFT_POSITION,
  RIGHT_POSITION,
  TOP_POSITION,
} from './panel/side-selector'

export const QuickOrientation = () => {
  const { updateCameraPosition, cameraControlRef } = useCoffinEditor()

  const SIDES = [
    null,
    'Bagside',
    null,
    'Venstre',
    'Top',
    'Højre',
    'zoom-out',
    'Front',
    'zoom-in',
  ]

  const zoom = async (side: 'zoom-in' | 'zoom-out') => {
    const zoomInterval = 0.1
    const zoom = side === 'zoom-in' ? 1 - zoomInterval : 1 + zoomInterval
    const position = cameraControlRef.current?.camera.position.toArray()
    await updateCameraPosition(
      position?.map((v) => v * zoom) as [number, number, number],
    )
  }

  return (
    <div className='absolute bottom-4 right-4'>
      <div>
        <div className='grid grid-cols-3 gap-1'>
          {SIDES.map((side, i) => {
            const taller = side && ['Venstre', 'Top', 'Højre'].includes(side)
            const isZoom = side?.startsWith('zoom')

            if (isZoom)
              return (
                <div
                  key={i}
                  className={cn(
                    'flex aspect-square cursor-pointer items-center justify-center rounded-sm p-1 text-slate-500 hover:bg-primary hover:text-primary-foreground',
                  )}
                  onClick={() => zoom(side as 'zoom-in' | 'zoom-out')}
                >
                  {side === 'zoom-in' ? (
                    <ZoomIn size={20} />
                  ) : (
                    <ZoomOut size={20} />
                  )}
                </div>
              )

            if (!side) return <div key={i} />
            return (
              <div
                key={i}
                className={cn(
                  'flex aspect-square cursor-pointer items-center justify-center rounded-sm border bg-background/50 p-1 text-xs backdrop-blur-sm hover:bg-primary hover:text-primary-foreground',
                  side === 'Venstre' && 'rounded-l-lg',
                  side === 'Højre' && 'rounded-r-lg',
                  side === 'Bagside' && 'rounded-t-lg',
                  side === 'Front' && 'rounded-b-lg',
                  taller && 'lg:aspect-auto lg:h-24',
                )}
                onClick={async () => {
                  await updateCameraPosition(
                    {
                      Top: TOP_POSITION,
                      Venstre: LEFT_POSITION,
                      Front: FRONT_POSITION,
                      Højre: RIGHT_POSITION,
                      Bagside: BACK_POSITION,
                    }[side] ?? TOP_POSITION,
                  )
                }}
              >
                {side}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
